<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title flex-between-center flex-wrap">
				<div><i class="far fa-history text-primary"></i>{{$t('assets.1')}}</div>
				
				<div class="d-flex">
					<div class="flex-start-center fs-px-14 exchange_btn btn-primary px-3 rounded-pill me-3 cursor-pointer" @click="GotoPackagePage()">
						<i class="far fa-cubes me-1"></i>
						<small>{{$t('assets.5')}}</small>
					</div>	
					<div class="flex-start-center fs-px-14 exchange_btn btn-primary px-3 rounded-pill cursor-pointer" @click="GotoExchangePage()">
						<i class="far fa-exchange me-1"></i>
						<small>{{$t('assets.6')}}</small>
					</div>
				</div>
			</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			
			<div class="asset_box mb-4">
				<div class="w-100">
					<div class="flex-between-center">
						<div>{{$t("dashboard.4")}}</div>
					</div>
					<div class="flex justify-content-between align-items-center">
						<div class="txt_box d-flex flex-wrap break-all align-items-center">{{numberWithCommas(my_assest)}}</div>
						<div class="icon opacity-25"><i class="fas fa-lg fa-sack-dollar"></i></div>
					</div>
				</div>
			</div>

			<!--section_right_inner-->
			<div class="notice_table04">
				<table style="width:100%" class="main_table02">
					<thead>
						<tr>
							<th>{{$t('assets.2')}}</th>
							<th>{{$t('assets.3')}}</th>
							<th>{{$t('assets.4')}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in bonusHistory" :key="index">
							<td>{{item.date}}</td>
							<td>{{CheckType(item.type)}}</td>
							<td>{{numberWithCommas(item.point)}}</td>
							
						</tr>
					</tbody>
					<tbody></tbody>
				</table>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
export default {
	data(){
		return{
			my_assest : 0,
			bonusHistory: [],
		}
	},
	mounted () {
		this.GetPointList()
		this.GetMyAssest()
		
	},
	methods : {
		GetMyAssest(){
			const body = {};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/dashboard/GetMyAssets`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							
							this.my_assest = body.info.point;
						}else if(res.data.code=='9999'){
							// 
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		GotoPackagePage(){
			this.$router.push({path : `/${this.$i18n.locale}/Membership`})
		},
		GotoExchangePage(){
			this.$router.push({path : `/${this.$i18n.locale}/Transfer`})
		},
		CheckType(type){
			

			// 번역 잘 되는지 확인
			if(type =='D'){
				return this.$t('assets.20')
			}else if(type =='W'){
				return this.$t('assets.21')
			}
		},
		GetPointList(){
			const body = { };
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/extra/GetPointList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.bonusHistory = body.list
					
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		numberWithCommas(x) {
			var parts=x.toString().split(".");
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		},
	}
}
</script>
<style lang="scss" scoped>
.exchange_btn{
}
</style>