<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-history text-primary"></i>{{$t('history.5')}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="notice_table04">
				<table style="width:100%" class="main_table03">
					<thead>
						<tr>
							<th>{{$t('history.7')}}</th>
							<th>{{$t('history.8')}}</th>
							<th>{{$t('history.9')}}</th>
							<th style="min-width: 100px">{{$t('history.10')}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in list" :key="index">
							
							<td>{{ CheckSales(item.target) }}</td>
							<td>{{numberWithCommas(item.point)}}</td>
							<td>{{CheckStatus(item.status)}}</td>
							<td style="min-width: 100px">{{item.date}}</td>
						</tr>
					</tbody>
					<tbody></tbody>
				</table>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			list : [],
			common_sales : []
		}
	},
	mounted(){
		this.GetSalesList()
		this.GetCommonSalesList()
	},
	methods : {
		CheckStatus(status){
			if(status =='H'){
				return this.$t('history.11')
			}else if(status =='Y'){
				return this.$t('history.12')
			}
		},
		CheckSales(code){
			let text = '';

			for (const el of this.common_sales) {
				if(el.code == code){
					text = el.name;
				}
			}

			return text;
		},
		GetSalesList(){
							
			const body = { };
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sales/GetSalesList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.list = body.list	
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
			
			
		},
		GetCommonSalesList(){
							
			const body = { };
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/sales/GetCommonSalesList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.common_sales = body.list	
						}
					}

				}
			).catch(() => {});	
		},
		numberWithCommas(x) {
			var parts=x.toString().split(".");
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		},
	}
}
</script>
<style>
</style>